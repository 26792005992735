<template>
  <v-row>
    <v-col cols="12" md="12" v-for="(obra, index) in empreendimentos" :key="index">
      <table-documentos :object="object" :obraId="obra" :userId="userId"></table-documentos>
    </v-col>
  </v-row>
</template>

<script>
import Cookies from 'js-cookie';
import TableDocumentos from './TableDocumentos.vue'

export default {
  props: {},
  data() {
    return {
      object: 'obra',
      empreendimentos: []
    };
  },
  mounted() {
    // Pega os empreendimentos do cookie
    const empreendimentosData = JSON.parse(Cookies.get('payloadEmpreendimentos'));
    this.empreendimentos = empreendimentosData.map(x => x.obra);
  },
  components: {
    TableDocumentos
  }
}
</script>
